.waveformcontianer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    gap: 16px;
}

.wave {
    /* width: 100%; */
    width: calc(100% - 32px - 36px - 32px);
    height: 90px;
}

.wave>wave:not(:last-child) {
    display: none !important;
}

.wave>wave::-webkit-scrollbar {
    display: none !important;
}

.playbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #efefef;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 3px;
}

.playbutton:hover {
    background-color: #ddd;
}

.btn_play_pause {
    width: 32px;
    height: 32px;
    border: 1px solid #E5E8ED;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_play_pause img {
    width: 20px;
    height: 20px;
}

.audio_time {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F22;
    white-space: nowrap;
}

.waveform {
    height: 32px;
}