.wrapper .map {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 0;
}

 .wrapper {
    width: 100%;
    overflow-x: hidden;
}

.wrapper .toast_successfully_login {
    position: relative;
    top: 70px;
    max-width: 540px;
    background-color: #1971C2;
    padding: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
    gap: 8px;
    animation-duration: 0.5s !important;
}

.wrapper .toast_successfully_login div:first-child {
    background-color: #ffffff;
    border-radius: 50%;
}

.wrapper .toast_successfully_login div:first-child::after {
    border-color: #1971C2;
}

.wrapper .toast_successfully_login div:last-child {
    margin: 0px;
}

.wrapper .inner_wrapper {
    width: 100%;
    position: relative;
}

/* Map */

.wrapper .map-container {
    height: 100%;
    width: 100%;
}

.wrapper .gmnoprint {
    position: absolute !important;
    bottom: 0px !important;
    left: 0px !important;
}

.wrapper #map_main {
    height: 100vh;
}

.wrapper .mapouter {
    position: relative;
    text-align: right;
    height: 100%;
    width: 100%;
}

.wrapper .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
}

.wrapper .gmap_canvas .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    height: 100%;
    z-index: 1;
}

.wrapper .map_content {
    position: absolute;
    top: 0px;
    left: 0px;
}

.wrapper .nav_search {
    padding: 16px;
    gap: 16px;
}

.wrapper .nav_logo {
    background-image: url('../../../public/assets/images/white-bg-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 72px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000029;
}

.wrapper .gm-style .default-card {
    display: none !important;
}

.wrapper .gm-style {
    display: none !important;
    visibility: hidden !important;
}

.wrapper .btn_sel_city {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #5D6D83;
    width: 341px;
    background-color: #FFFFFF;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
    outline: none;
}

.wrapper .btn_sel_city:focus:not(:focus-visible) {
    background-color: #FFFFFF;
}

.wrapper .btn_sel_city:hover {
    background-color: #FFFFFF;
    outline: none;
}

.wrapper .btn_sel_city::after {
    position: absolute;
    top: 22px;
    right: 23px;
    width: 10px;
    height: 5px;
}

.wrapper .city_link_set {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.wrapper .city_name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #1D1F22;
}

.wrapper .sel_city_drop_menu {
    width: 341px;
    padding: 8px 8px 0px 8px !important;
    border: none !important;
    border-radius: 8px !important;
    transform: translate(0px, 53px) !important;
}

.wrapper .set_city_link {
    cursor: pointer !important;
    padding: 16px 8px !important;
    border-radius: 8px !important;
    background-color: #FFFFFF !important;
    margin: 0;
    margin-bottom: 8px;
}


.wrapper .set_city_link:hover {
    background-color: #F2F5FA !important;
}

.wrapper .set_city_link_active {
    border: 1px solid #546EF3 !important;
    background-color: #FFFFFF !important;
}

.wrapper .set_default {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #546EF3;
}

/* All report */

.wrapper .submit_your_response {
    width: 456px;
    height: 100%;
    transition: all 0.6s;
}

.wrapper .offcanvas_submit_response_set {
    width: 456px !important;
    transition: all 0.6s !important;
    border-left: 0px !important;
    background-color: transparent !important;
}

.wrapper .offcanvas_submit_response_upload {
    width: 456px !important;
    transition: all 0.6s !important;
    border-left: 0px !important;
    background-color: transparent !important;
}

.wrapper .offcanvas_right {
    border-left: 0px !important;
    width: auto !important;
    height: 100vh;
    transition: all 0.6s !important;
    background-color: transparent !important;
}

.wrapper .offcanvas_all_report {
    width: 432px !important;
    height: 100vh;
    overflow: auto;
    background-color: #FFFFFF;
    box-shadow: -8px 0px 8px 0px #00000029;
}

.wrapper .offcanvas_all_report::-webkit-scrollbar {
    display: none;
}

.wrapper .inner_all_report {
    height: 100%;
    position: relative;
}

.wrapper .btn_drower {
    width: 24px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    border: none !important;
    outline: none;
    background-color: transparent !important;
    position: relative;
    background-image: url('/public/assets/images/drower.png');
}

.wrapper .drower_pan {
    width: 8px;
    height: 100vh;
    background-color: #FFFFFF;
}

.wrapper .set_right_drower_main {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.wrapper .btn_drower_right {
    z-index: 99;
    width: 24px;
    height: 94px;
    padding: 0 !important;
    border: none !important;
    outline: none;
    background-color: transparent !important;
    background-image: url('/public/assets/images/drower.png');
}

.wrapper .inner_drower {
    position: absolute;
    top: 50%;
    left: -32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/assets/images/drower.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 94px;
}

.wrapper .inner_drower_submit_res {
    position: absolute;
    top: 50%;
    left: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/assets/images/drower.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 94px;
}

.wrapper .set_heading_all_report {
    padding: 24px;
    background-color: #FFF;
    position: sticky;
    top: 0px;
    z-index: 9;
}

.wrapper .heading_all_report {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #1D1F22;
}

.wrapper .report_data_tab {
    position: relative;
}

.wrapper .report_tab {
    padding-left: 24px !important;
    border-bottom: 2px solid #E5E8ED !important;
    position: sticky;
    background: #FFF;
    top: 82px;
    z-index: 9;
}

.wrapper .report_link {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: #94A1B3 !important;
    padding: 4px 16px 16px 16px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none !important;
    outline: none;
}

.wrapper .report_link:focus-visible {
    box-shadow: none !important;
}

.wrapper .report_link:hover {
    border-radius: 0px;
    border-top-color: #FFFFFF !important;
    border-right-color: #FFFFFF !important;
    border-left-color: #FFFFFF !important;
}

.wrapper .report_link[aria-selected="true"] {
    padding: 4px 16px 16px 16px;
    border: none !important;
    border-bottom: 2px solid #546EF3 !important;
    color: #546EF3 !important;
}

.wrapper .report_link[aria-selected="true"] .open_number_disabled {
    color: #1D1F22;
}

.wrapper .open_number_disabled {
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #94A1B3;
    background-color: #F2F5FA;
    border: 1px solid #E5E8ED;
    border-radius: 4px;
}

.wrapper .report_link_disabled {
    color: #94A1B3 !important;
}

.wrapper .report_item {
    border: none;
    outline: none;
    border-radius: 0px;
    padding: 0;
}

.wrapper .open_close {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

/* Filter container */

.wrapper .filter_container {
    padding: 0px !important;
}

.wrapper .filter_header {
    padding: 16px 24px;
    box-shadow: 0px 4px 4px 0px #00000029;
    gap: 16px;
    position: sticky;
    top: 129px;
    background-color: #FFFFFF;
    z-index: 9;
}

.wrapper .form_search {
    width: 100%;
}

.wrapper .search_group {
    background-color: #F2F5FA;
    border: 1px solid #E5E8ED;
    border-radius: 8px;
}

.wrapper .search_icon_set {
    margin: 3.75px 2.5px 2.5px 3.75px;
}

.wrapper .search_icon {
    border: none !important;
    background-color: #F2F5FA !important;
    padding: 10px 4px 10px 8px !important;
}

.wrapper .search_input {
    padding: 10px 8px 10px 0px !important;
    margin: 0 !important;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    color: #5D6D83 !important;
    background-color: #F2F5FA !important;
}

.wrapper .search_input:focus {
    box-shadow: none !important;
    background-color: #F2F5FA;
}

.wrapper .filter_modal_set {
    width: auto !important;
    height: auto !important;
    overflow: hidden !important;
    position: absolute !important;
    left: -272px !important;
    top: 41px !important;
}


.wrapper .filter_modal_dialogue {
    min-width: 314px;
    height: 790px;
    margin: 0px !important;
}

.wrapper .filter_modal_body {
    padding: 0 !important;
}

.wrapper .btn_filter {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper .filter_modal_header {
    padding: 25px 24px 14px 24px !important;
    border-bottom: 1px solid #E5E8ED !important;
}

.wrapper .btn_close_filter_modal:focus {
    box-shadow: none !important;
}

.wrapper .btn_close_filter_modal {
    width: 32px !important;
    height: 32px !important;
    padding: 0px !important;
    background: url('../../../public/assets/images/close32.png') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 19px 19px !important;
    opacity: 1 !important;
    margin-left: auto !important;
}

.wrapper .filter_modal_content {
    width: 314px !important;
    border: none !important;
    box-shadow: 0px 4px 32px 0px #0000001F;
    clip-path: polygon(95% 0, 97% 1%, 100% 1%, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 1%, 92% 1%);
}

.wrapper .filter_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.wrapper .filter_data {
    padding: 20px 24px 23px 24px;
}

.wrapper .date_label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1D1F22CC;
    padding-bottom: 4px;
}

.wrapper .date_input {
    width: 100%;
    padding: 8px 8px 8px 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #57667B80;
    border: 1px solid #E5E8ED;
    border-radius: 8px;
    outline: none;
}

.wrapper .from {
    padding-bottom: 12px;
}

.wrapper .to {
    padding-bottom: 20px;
}

.wrapper .date_input::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../../../public/assets/images/calendar_today.png') no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
}

.wrapper .btn_filter::after {
    display: none;
}

.wrapper .by_type {
    padding-bottom: 16px;
}

.wrapper .by_categories {
    padding-bottom: 20px;
}

.wrapper .by_type_head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #1D1F22CC;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .check_list {
    padding: 11px 16px 0px 16px;
    background-color: #F2F5FA;
    border-radius: 8px;
}

.wrapper .check_input_set {
    padding-bottom: 11px;
    margin-bottom: 0px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.wrapper .check_input {
    width: 20px !important;
    height: 20px !important;
    outline: none !important;
    border: 0.83px solid #57667B80 !important;
    border-radius: 3.33px !important;
}

.wrapper .check_input:checked {
    background-image: url('../../../public/assets/images/check_small.png');
    background-repeat: no-repeat;
    background-size: 10px 8px;
    background-position: center;
    background-color: #546EF3 !important;
    border-color: #546EF3 !important;
}

.wrapper .check_input:focus {
    border-color: #57667B80 !important;
    box-shadow: none !important;
}

.wrapper .check_label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #5D6D83;
    padding-left: 8px;
}

.wrapper .btn_clear_all {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #546EF3;
    background-color: transparent;
    border: none;
}

.wrapper .btn_show_result {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #FFFFFF;
    background-color: #546EF3 !important;
    padding: 6px 28px;
    border-radius: 8px;
    border: none;
}

/* Fault list */

.wrapper .fault_list {
    padding: 24px 24px 16px 24px;
    /* height: 80.6vh; */
}

.wrapper .fault_list_nav {
    display: block;
    /* max-height: 100%; */
    overflow: auto;
    list-style-type: none;
    padding: 0;
    margin: 0 !important;
    border: 1px solid #CDD1D7;
    border-radius: 8px;
}

.wrapper .fault_list_nav::-webkit-scrollbar {
    display: none;
}

/* open_main_container */

.wrapper .open_main_container {
    padding: 0px 24px 0px 24px;
    position: relative;
    height: 100vh;
    overflow: auto;
}

.wrapper .open_main_container::-webkit-scrollbar {
    display: none;
}

.wrapper .head_open_close {
    position: sticky;
    padding-top: 24px;
    top: 0px;
    z-index: 9;
    background-color: #fff;

}

.wrapper .btn_response_set {
    width: 384px;
    position: fixed;
    bottom: 0px;
    padding: 16px 0px;
    background-color: #fff;
    z-index: 9;
}

.wrapper /* .open_tab_content {
    display: none;
} */

.wrapper .closed_tab_content {
    display: none;
}

.wrapper .reported_item_details {
    max-width: 433px;
    width: 433px;
    height: 100%;
    border-left: 1px solid #CDD1D7;
    background-color: #FFFFFF;
    transition: width 0.6s !important;
    /* margin-left: -432px; */
    animation: reported_item_details_animation 0.6s ease-in-out;
}

@keyframes reported_item_details_animation {

    0% {
        margin-right: -432px;
    }

    100% {
        margin-right: 0;
    }

}

.wrapper .reported_item_details_close {
    max-width: 433px;
    width: 433px;
    height: 100%;
    border-left: 1px solid #CDD1D7;
    background-color: #FFFFFF;
    transition: width 0.6s !important;
    /* margin-left: -432px; */
    animation: reported_item_details_animation_close 0.6s ease-in-out;
}

@keyframes reported_item_details_animation_close {

    0% {
        margin-right: 0;
    }

    100% {
        margin-right: -432px;
    }

}

.wrapper .head_open {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #1D1F22;
    padding: 5px 8px;
    border: 1px solid #CDD1D7;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.wrapper .btn_close {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: none;
}

.wrapper .set_fault_head_open {
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
    background-color: #fff;
    position: sticky;
    top: 50px;
    z-index: 9;
}

.wrapper .carousel_open {
    position: sticky;
    top: 151px;
    z-index: 9;
    background-color: #fff;
}

.wrapper .fault_data_head_open {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: #1D1F22;
    padding-bottom: 8px;
    margin: 0;
}

.wrapper .date_para {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.wrapper .fault_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}

.wrapper .head_report_carousel {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

/* Indicator2 */
.wrapper .carousel-indicators2 {
    position: absolute;
    top: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}

.wrapper .carousel-indicators2 li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px;
}

.wrapper .carousel-indicators2 .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff;
}

/* close tab pane */

.wrapper .response_head {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .response_para {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1D1F22;
}

/* carousel */

.wrapper .image_indicators {
    position: absolute !important;
    bottom: -85px !important;
    margin: 0px !important;
    padding-top: 13px !important;
    gap: 8px;
    background-color: #fff;
}

.wrapper .image_indicators [data-bs-target] {
    text-indent: 0px !important;
}

.wrapper .image_indic_set {
    border: 0 !important;
    width: 70px !important;
    height: 72px !important;
    margin: 0 !important;
    opacity: 1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    position: relative;
}

.wrapper .carousel_indicators_play_button {
    width: 26px;
    height: 26px;
    background-color: #fff;
    position: absolute;
    top: 0px;
    border-radius: 50%;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper .carousel_indicators_play_button img {
    margin-right: -1.5px;
}

.wrapper .image_indic_set[aria-current="true"] {
    border: 2px solid #546EF3 !important;
}

.wrapper .image_indic_set[aria-current="true"] .carousel_indicators_play_button {
    opacity: 0.5;
}

.wrapper .image_indic_set[aria-current="true"] .indic_image {
    opacity: 0.5;
}

.wrapper .indic_image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.wrapper .image_indic_set .react-thumbnail-generator {
    height: 100%;
}

.wrapper .image_indic_set .react-thumbnail-generator img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.wrapper .image_indic_set.active .react-thumbnail-generator img {
    opacity: 0.5;
}


.wrapper .open_carousel_item {
    height: 162px;
}

.wrapper .video_carousel {
    height: 100%;
}

.wrapper .round_indicators_set {
    position: absolute !important;
    bottom: -128px !important;
    background-color: #fff;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 11px !important;
    padding-bottom: 24px !important;
}

.wrapper .round_indicators {
    width: 8px !important;
    height: 8px !important;
    background-color: #E0E0E0 !important;
    border-radius: 50% !important;
    margin: 0px 4px !important;
    opacity: 1 !important;
}

.wrapper .round_indicators[aria-current="true"] {
    background-color: #546EF3 !important;
}

.wrapper .round_indicators[data-bs-target] {
    background-color: #E0E0E0;
    text-indent: 0px !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
}

/* details_pan */

.wrapper .details_pan {
    padding-top: 130px;
}

.wrapper .details_tab {
    border-bottom: 2px solid #E5E8ED;
    background-color: #fff;
    position: sticky;
    top: 455px;
    width: 100%;
    z-index: 9;
}

.wrapper .details_tab_link {
    padding: 4px 16px 16px 16px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    outline: none;
    color: #94A1B3 !important;
    border: none !important;
}

.wrapper .details_tab_link:hover {
    border-radius: 0px;
    border-top-color: #FFFFFF !important;
    border-right-color: #FFFFFF !important;
    border-left-color: #FFFFFF !important;
}

.wrapper .details_tab_link[aria-selected="true"] {
    border: none;
    border-bottom: 2px solid #546EF3 !important;
    color: #546EF3 !important;
}

.wrapper .details_tab_link[aria-selected="true"] .open_number_disabled {
    color: #1D1F22;
}

.wrapper .open_number_disabled {
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #94A1B3;
    background-color: #F2F5FA;
    border: 1px solid #E5E8ED;
    border-radius: 4px;
}

.wrapper .location {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: #F2F5FA;
    gap: 8px;
}

.wrapper .location_head {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 4px;
}

.wrapper .logo_location {
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper .location_icon_set {
    width: 12px;
    height: 15px;
}

.wrapper .full_location {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .description {
    padding-bottom: 24px;
}

.wrapper .description_head {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .description_inner_para {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1D1F22;
    /* display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden; */
}

.wrapper .plus {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #546EF3;
    border: none;
    background-color: transparent;
}

.wrapper .description_para {
    display: none;
}

.wrapper .audio_player {
    gap: 16px;
    padding: 16px;
    background-color: #F2F5FA;
    border-radius: 8px;
    margin-bottom: 73px;
}

.wrapper .audio_head {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .audio_time {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F22;
    white-space: nowrap;
}

.wrapper .btn_play_pause {
    width: 32px;
    height: 32px;
    border: 1px solid #E5E8ED;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper .btn_play_pause img {
    width: 20px;
    height: 20px;
}

.wrapper .btn_response:disabled {
    background-color: #D2D8DF !important;
    opacity: 1 !important;
}

.wrapper .btn_response {
    width: 100%;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 14px 10px !important;
    box-shadow: none !important;
}

/* similer_fault_list */

.wrapper .similer_fault_list {
    padding-top: 24px;
    padding-bottom: 80px;
    border-bottom: 0px;
}

.wrapper .similer_problem_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #CDD1D7;
    /* display: block; */
    /* overflow: auto; */
    /* height: 417px; */
}

.wrapper .similer_problem_list::-webkit-scrollbar {
    display: none;
}

.wrapper .delete_item_drop_down {
    align-self: flex-start;
}

.wrapper .similer_problem_list_item {
    padding: 16px;
    border-bottom: 1px solid #CDD1D7;
}

.wrapper .similer_problem_list_item:last-child {
    border-radius: 0px 0px 8px 8px;
    border-bottom: 0px;
}

.wrapper .similer_problem_list_item:first-child {
    border-radius: 8px 8px 0px 0px;
}

.wrapper .problem_name {
    gap: 12px;
    padding-bottom: 12px;
}

/* submit_your_response */

.wrapper .btn_drower_sublimt_response {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent !important;
    position: absolute;
    top: calc(50% - 94px/2);
    left: -22px;
}

.wrapper .reponse_head {
    padding-top: 24px;
}

.wrapper .response_inner {
    width: 432px !important;
    background-color: #FFFFFF;
    height: 100%;
    padding: 0px 24px 16px 24px;
    box-shadow: -8px 0px 8px 0px #00000029;
}

.wrapper .reponse_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .response_heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .btn_close_reponse {
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper .reponse_text {
    padding: 12px 16px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #1D1F22 !important;
    background-color: #F2F5FA !important;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px !important;
    height: 220px;
    resize: none;
}

.wrapper .reponse_text::placeholder {
    color: #94A1B3 !important;
    font-size: 12px;
}

.wrapper .reponse_text::-webkit-input-placeholder {
    color: #94A1B3 !important;
    font-size: 12px;
}

.wrapper .reponse_text:focus {
    background-color: #F2F5FA !important;
    border-color: #E5E8ED !important;
    box-shadow: none !important;
}

.wrapper .upload_media {
    padding-top: 24px;
}

.wrapper .btn_response_fix {
    width: 384px;
    padding: 16px 0px;
    position: fixed;
    bottom: 0px;
}

.wrapper .uploaded_media {
    gap: 6px;
    flex-wrap: wrap;
}

.wrapper .upload_data {
    width: calc(25% - 4.5px);
    position: relative;
    height: 92px;
    border-radius: 8px;
}

.wrapper .upload_data img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

.wrapper .received_response_text {
    padding: 12px 16px 76px 16px;
    background-color: #F2F5FA;
    border: 1px solid #E5E8ED;
    border-radius: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #1D1F22;
}

.wrapper .btn_remove_img {
    position: absolute;
    top: 4px;
    right: 8px;
    width: 20px;
    height: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #FFF;
}

.wrapper .btn_remove_img img {
    width: auto;
    height: auto;
}

.wrapper .file_upload_form {
    position: relative;
}

.wrapper .file_upload {
    width: 100%;
    padding: 40px 0px 41px 0px;
    background-color: #F2F5FA;
    border-radius: 8px;
    text-align: center;
}

.wrapper .file_upload svg {
    width: 20px;
    height: 20px;
}

.wrapper .media_file {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #5D6D83;
    padding-top: 10px;
}

.wrapper .tap_link {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #546EF3;
}

.wrapper .input_doc_upload {
    cursor: pointer !important;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.wrapper .update_status {
    padding-top: 24px;
    padding-bottom: 80px;
}

.wrapper .open_close {
    padding-top: 4px;
    gap: 12px;
}

.wrapper .open_close_btn.open_close_btn_active {
    gap: 2px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #546EF3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #546EF3;
}

.wrapper .open_close_btn {
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #1D1F22;
    background-color: #FFFFFF;
    border: 1px solid #E5E8ED;
    border-radius: 8px;
}

.wrapper .submit_reponse_btn:disabled {
    background-color: #D2D8DF !important;
    opacity: 1 !important;
}

.wrapper .submit_reponse_btn_container {
    position: fixed;
    bottom: 0px;
    width: 384px;
    padding: 16px 0px;
    background-color: #fff;
}

.wrapper .submit_reponse_btn {
    width: 100%;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 14px 0px !important;
}

.wrapper .submit_reponse_btn:hover {
    color: #FFFFFF;
    background-color: #D2D8DF;
}


/* Show Results chart data */

.wrapper .show_reults_chart_data {
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.wrapper .set_chart_data {
    position: relative;
}

.wrapper .chart_data_modal {
    width: 976px !important;
    height: 348px !important;
    position: absolute !important;
    top: -362px !important;
}

.wrapper .chart_data_dialogue {
    min-width: 976px;
    height: 338px;
    border-radius: 8px;
    margin: 0 !important;
}

.wrapper .chart_data_content {
    border: none !important;
}

.wrapper .btn_chart {
    z-index: 99;
    color: #1D1F22 !important;
    padding: 12px 16px !important;
    background-color: #FFFFFF !important;
    border-radius: 8px !important;
}

.wrapper .btn_chart:active {
    border-color: #FFFFFF !important;
}

.wrapper .btn_chart:focus-visible {
    background-color: #FFFFFF;
}

.wrapper .btn_chart:hover {
    background-color: #FFFFFF;
}

.wrapper .btn_chart_head {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #1D1F22;
    padding-left: 10px;
}

.wrapper .chart_data_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #1D1F22;
    padding-top: 4px;
    padding-bottom: 4px;
}

.wrapper .btn_close_chart_data:focus {
    box-shadow: none !important;
}

.wrapper .btn_close_chart_data {
    width: 32px !important;
    height: 32px !important;
    background-color: #F2F5FA !important;
    border-radius: 50% !important;
    padding: 0px !important;
    margin: 0 !important;
    opacity: 1 !important;
    background-image: none !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.wrapper .chart_data_header {
    border: none !important;
    padding: 22px 27px 0px 27px !important;
}

.wrapper .chart_data_body {
    padding: 24px 27px 22px 27px !important;
}

.wrapper .report_card_chart {
    padding: 16px;
    border: 1px solid #CDD1D7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper .head_chart {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .chart_number {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .date_set {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
    margin: 0;
}

.wrapper .report_card_chart {
    width: calc(33.33% - 16px);
    gap: 24px;
}

.wrapper .report_card_chart>canvas {
    width: 124px !important;
    height: 76px !important;
}

.wrapper .charts {
    gap: 24px;
    padding-bottom: 24px;
}

.wrapper #myChart_one {
    width: 132px !important;
    height: 76px !important;
}

.wrapper #myChart_two {
    width: 132px !important;
    height: 76px !important;
}

.wrapper #myChart_three {
    width: 132px !important;
    height: 76px !important;
}

.wrapper .address_cards {
    gap: 24px;
}

.wrapper .city_card {
    width: calc(33.33% - 16px);
    padding: 16px;
    border: 1px solid #CDD1D7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.wrapper .heading_report_issue {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 16px;
}

.wrapper .city_title {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .city_name_chart {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .report_count {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .modal-backdrop {
    background-color: #000917 !important;
    opacity: 0.8 !important;
}

.wrapper .zoom_modal_btn {
    width: 40px;
    height: 40px;
    background-color: #0000007A !important;
    border-radius: 50% !important;
    padding: 0 !important;
    position: absolute;
    top: 8px;
    right: 6px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/* <!-- Edit Profile Modal 14-06-2023 --> */
.wrapper .user_profile_modal_btn {
    padding: 0 !important;
    width: 34px;
    height: 34px;
    background-color: #F2F5FA !important;
    border: none !important;
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.wrapper .user_profile_modal_btn:focus-visible {
    border-color: #FFFFFF !important;
}

.wrapper .user_profile_modal_btn:active {
    border: none !important;
}

.wrapper .user_profile_modal_dialog {
    max-width: 420px !important;
}

.wrapper .user_profile_modal_content {
    border: none !important;
    border-radius: 12px !important;
}

.wrapper .user_profile_modal_body {
    padding: 23px 32px 29px 32px !important;
}

.wrapper .user_logo_edit_profile {
    border-radius: 50%;
    position: absolute;
    top: -48px;
    left: 32px;
}

.wrapper .edit_profile_bg_img_two {
    margin-left: 14px;
    margin-right: 12px;
    align-self: flex-end;
}

.wrapper .edit_profile_bg_img_three {
    align-self: flex-end;
}

.wrapper .edit_profile_bg_img_four {
    align-self: flex-end;
    margin-left: 10px;
}

.wrapper .user_profile_modal_header {
    height: 124px;
    padding: 8px 8px 0px 19px !important;
    background-color: #F2F5FA;
    border: none;
    flex-direction: column;
}

.wrapper .close_modal_edit_profile_btn:hover {
    opacity: 1;
}

.wrapper .close_modal_edit_profile_btn:focus {
    box-shadow: none !important;
}

.wrapper .close_modal_edit_profile_btn {
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
    padding: 0 !important;
    align-self: flex-end;
    opacity: 1 !important;
    background-image: none !important;
}

.wrapper .link_edit_set {
    margin: 0;
    text-align: right;
    padding-bottom: 15px;
}

.wrapper .edit_profile_link:active {
    border-color: #FFFFFF !important;
}

.wrapper .edit_profile_link {
    padding: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: #546EF3 !important;
}

.wrapper .user_name {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin: 0;
    padding-bottom: 32px;
}

.wrapper .email_set {
    padding-bottom: 28px;
}

.wrapper .password_set {
    padding-bottom: 32px;
}

.wrapper .label_email {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 8px;
}

.wrapper .email {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #1D1F22;
    margin: 0;
}

.wrapper .password {
    gap: 6px;
    margin: 0;
}

.wrapper .password_dots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #5D6D83;
}

.wrapper .preference {
    padding-top: 24px;
    border-top: 1px solid #D9D9D9;
}

.wrapper .head_preference {
    padding-bottom: 10px;
}

.wrapper .showing_report {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83;
}

.wrapper .set_preference_link:active {
    border-color: #FFFFFF !important;
}

.wrapper .set_preference_link {
    padding: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: #546EF3 !important;
    cursor: pointer !important;
}

.wrapper .problem_name_set_pref {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #1D1F22;
    padding: 4px 8px;
    border: 1px solid #E5E8ED;
    border-radius: 5px;
}

.wrapper .time_passed {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #1D1F22;
    padding: 4px 8px;
    border: 1px solid #E5E8ED;
    border-radius: 5px;
    margin-left: 10px;
}

/* <!-- Change profile modal 14-06-2023 --> */

.wrapper .edit_profile_modal_dialog {
    max-width: 420px !important;
}

.wrapper .edit_profile_modal_header {
    background-color: #F2F5FA !important;
}

.wrapper .edit_profile_modal_content {
    border: none !important;
    border-radius: 12px !important;
    background-color: #FFFFFF !important;
}

.wrapper .edit_profile_modal_header {
    padding: 16px 16px 28px 32px !important;
    border: none !important;
}

.wrapper .edit_profile_modal_btn:hover {
    opacity: 1 !important;
}

.wrapper .edit_profile_modal_btn:focus {
    box-shadow: none !important;
}

.wrapper .edit_profile_modal_btn {
    align-self: flex-start;
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 1 !important;
    background-image: none !important;
}

.wrapper .edit_profile {
    padding-top: 20px;
}

.wrapper .edit_profile_modal_body {
    padding: 32px 32px 34px 32px;
}

.wrapper .head_edit_profile {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #1D1F22;
    margin: 0;
    padding-bottom: 4px;
}

.wrapper .head_edit_para {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F2299;
    margin: 0;
}

.wrapper .input_set {
    padding-bottom: 16px;
}

.wrapper .input_label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83;
    margin: 0 !important;
    padding-bottom: 6px;
}

.wrapper .input_field {
    width: 100% !important;
    padding: 13px 16px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    color: #1D1F22 !important;
    background-color: #F2F5FA !important;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.wrapper .input_field[type="password"] {
    font-family: "fontello";
    font-style: normal;
    font-weight: bold;
    color: #5D6D83 !important;
    padding: 8px 16px !important;
    background-color: #F2F5FA !important;
    border: 1px solid #E5E8ED !important;
    font-size: 25px !important;
    line-height: 30px !important;
    vertical-align: middle !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 5px;
}

.wrapper .input_field[type="password"]::placeholder {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    letter-spacing: normal;
}

.wrapper .input_field:focus {
    border: 1px solid #5D6D83 !important;
}

.input_field_is_invalid:focus,
.wrapper .input_field_is_invalid[type="password"]:focus {
    border: 1px solid #F35959 !important;
}

.input_field_is_invalid,
.wrapper .input_field_is_invalid[type="password"] {
    border: 1px solid #F35959 !important;
    background-image: none !important;
}

.wrapper .invalid_feedback {
    color: #F35959 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.wrapper .input_set_home {
    margin-bottom: 16px;
}

.wrapper .btn_save:disabled {
    background-color: #D2D8DF !important;
    opacity: 1 !important;
}

.wrapper .btn_save {
    width: 100%;
    padding: 14px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
}

.wrapper .link_change_password {
    margin: 0;
    text-align: right;
    padding-bottom: 15px;
}

.wrapper .change_password_link {
    padding: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    color: #546EF3 !important;
    border: 0px !important;
}


/* <!-- Change password modal 14-06-2023 --> */

.wrapper .btn_change_password:disabled {
    background-color: #D2D8DF !important;
    opacity: 1 !important;
}

.wrapper .btn_change_password {
    width: 100%;
    padding: 14px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
    margin-top: 16px;
}

/* <!-- Change password successfully modal 14-06-2023 --> */

.wrapper .change_password_success_dialog {
    max-width: 420px !important;
}

.wrapper .change_password_success_modal_body {
    text-align: center;
    padding-top: 120px !important;
    padding-bottom: 97px !important;
}

.wrapper .msg_password_changed {
    font-size: 24px;
    font-weight: 600;
    line-height: 22px;
    color: #1D1F22;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 12px;
}

.wrapper .msg_para_success {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F2299;
    margin: 0;
    padding-bottom: 20px;
}

.wrapper .btn_okay:hover {
    color: #FFFFFF;
    background-color: #546EF3;
}

.wrapper .btn_okay {
    padding: 14px 37px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
    border-radius: 8px !important;
}

.wrapper .change_password_success_modal_footer {
    padding: 0px 43px;
}

/* <!-- Set preference modal 14-06-2023 --> */

.wrapper .show_data:focus {
    box-shadow: none !important;
    border: 1px solid #E5E8ED !important;
    color: #1D1F22 !important;
    background-color: #F2F5FA !important;
}

.wrapper .show_data {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    color: #1D1F22 !important;
    border: 1px solid #E5E8ED !important;
    background-color: #F2F5FA !important;
    border-radius: 8px !important;
    padding: 15px 19px 15px 16px !important;
    margin-bottom: 32px !important;
    background-image: url('../../../public/assets/images/arrow_drop_down.png') !important;
    background-repeat: no-repeat !important;
    background-position: right 19px center !important;
    background-size: 10px 5px !important;
}

.wrapper .left_head_category {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83;
}

.wrapper .reset_link {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #546EF3;
    cursor: pointer;
}

.wrapper .categories_of_report {
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 32px;
}

.wrapper .head_category {
    padding-bottom: 10px;
}

.wrapper .categories_of_report>.active {
    border: 1px solid #546EF3;
}

.wrapper .category_name {
    border: 1px solid #E5E8ED;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #1D1F22 !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

@media screen and (max-height:992px) {
    .wrapper .open_main_container {
        height: 100vh;
        display: block !important;
        overflow: auto !important;
    }

    .wrapper .open_main_container::-webkit-scrollbar {
        display: none;
    }

    .wrapper .response_inner {
        height: 100vh;
        display: block !important;
        overflow: auto !important;
    }

    .wrapper .response_inner::-webkit-scrollbar {
        display: none;
    }

}