.react-thumbnail-generator{
    height: 100%;
    width: 100%;
}

.react-thumbnail-generator img,
.react-thumbnail-generator video{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}