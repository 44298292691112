


.fault_list_nav_item.active {
    border-left: 4px solid #546EF3 !important;
    border-right: 4px solid #546EF3 !important;
    background-color: #F2F5FA !important;
    padding: 16px 12px !important;
}

.fault_list_nav_item:last-child{
    border-bottom: none !important;
}

.fault_list_nav_item {
    border: none !important;
    padding: 16px 16px !important;
    position: relative;
    border-bottom: 1px solid #CDD1D7 !important;
    cursor: pointer;
}

.fault_list_nav_link {
    border-radius: 0px !important;
    border: none !important;
    cursor: pointer;
}

.fault_list_nav_item:last-child .fault_list_nav_link{
    border-bottom: none !important;
}

.set_fault_head {
    gap: 12px;
}

.water_leak_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_item.active .water_leak_bg {
    background-color: #FFFFFF;
}

.litter_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_item.active .litter_bg {
    background-color: #FFFFFF;
}

.road_repair_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_item.active .road_repair_bg {
    background-color: #FFFFFF;
}

.street_light_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_item.active .street_light_bg {
    background-color: #FFFFFF;
}

.accident_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_item.active .accident_bg {
    background-color: #FFFFFF;
}


.btn_three_dot_set_report_item {
    display: flex !important;
    align-items: center !important;
    border: none !important;
    padding: 4px 10px !important;
    border-radius: 50% !important;
}

.btn_three_dot_set_report_item:focus:not(:focus-visible) {
    background-color: transparent;
}

.btn_three_dot_set_report_item::after {
    display: none !important;
}

.btn_three_dot_set_report_item.show{
    background: #E5E8ED !important;
}

.three_dot_icon_set_report_item {
    /* margin: 3px 10px; */
    align-self: flex-start;
}

.btn_three_dot_dropdown_menu {
    padding: 0px !important;
    border: 1px solid #CDD1D7 !important;
    border-radius: 8px !important;
    min-width: 109px !important;
    transform: translate(0px, 33px) !important;
}

.btn_three_dot_dropdown_menu_item:active {
    background-color: #cacaca !important;
}

.btn_three_dot_dropdown_menu_item {
    width: 109px !important;
    padding: 12px 16px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 22px;
    color: #1D1F22 !important;
    border-radius: 8px !important;
}

.fault_list_nav_item.active .btn_three_dot_dropdown{
    right: 8px;
}

.btn_three_dot_dropdown {
    width: 24px;
    height: 24px;
    align-self: flex-start;
    position: absolute;
    top: 16px;
    right: 12px;
    z-index: 8;
}

.fault_data_head {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #1D1F22;
    padding-bottom: 2px;
    margin: 0;
}


.date_icon_set {
    margin: 1.33px 2px 0.67px 2px;
}

.date_para {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.fault_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}

.fault_details {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #1D1F22;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.problem_para {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}

.caution_icon_set {
    margin: 1px 2px;
}