.fogetpassword_container {
    width: 100%;
    background-color: #FFFFFF;
}

.upper_part {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46.1vh;
    max-height: 472px;
    background-color: #F2F5FA;
    position: relative;
    width: 100%;
}

.bottom_part {
    width: 100%;
    position: relative;
    height: 46.1vh;
    max-height: 472px;
}

.set_bottom_part {
    position: absolute;
    top: -137px;
}

.below_img_footer {
    width: 50%;
}

.below_img {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.heading_left_forget {
    gap: 12px;
    position: absolute;
    top: 90px;
}

.forget_psw_card {
    width: 408px;
    padding: 26px 36px 32px 32px;
    background-color: #FFFFFF;
    border: 1px solid #E5E8ED;
    border-radius: 12px;
    box-shadow: 0px 0px 24px 0px #0000000A;
}

.head_forget_psw {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin: 0;
    padding-bottom: 4px;
}

.input_set_login {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83 !important;
    background-color: #F2F5FA !important;
    padding: 13px 16px;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px;
}

.email_set_forget {
    margin-bottom: 16px;
}

.para_forget_psw {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F2299;
    margin-bottom: 36px;
}

.back_to_login_link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #546EF3 !important;
    margin-top: 32px;
    cursor: pointer;
}