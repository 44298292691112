
/* <!-- Zoom image modal Modal 14-06-2023 --> */

.modal#zoom_image_modal{
    max-height: 100vh;
    height: 100vh !important;
}

.zoom_image_modal_content_image{
    max-height: 680px;
    object-fit: cover;
    object-position: top;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
}

.zoom_image_modal_body {
    text-align: center !important;
    padding: 0 !important;
}

.zoom_image_modal_content {
    background-color: transparent !important;
    border: none !important;
}

.video_zoom_set {
    height: 680px !important;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
}

.zoom_image_modal_dialog {
    max-width: 1080px !important;
}

.close_zoom_image_modal_btn:hover {
    opacity: 1 !important;
}

.close_zoom_image_modal_btn:focus {
    box-shadow: none !important;
}

.close_zoom_image_modal_btn {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
    padding: 0 !important;
    align-self: flex-end;
    opacity: 1 !important;
    background-image: url('../../../../public/assets/images/close-white.png') !important;
    background-repeat: no-repeat !important;
    background-size: 14px 14px !important;
}

.round_indicators_set_zoom {
    position: absolute !important;
    bottom: -60px !important;
    background-color: transparent;
    gap: 8px;
}

.round_indicators_zoom {
    width: 12px !important;
    height: 12px !important;
    background-color: #E0E0E0;
    border-radius: 50%;
    margin: 0 !important;
    opacity: 1 !important;
}

.round_indicators_zoom.active {
    background-color: #546EF3 !important;
    opacity: 1 !important;
}

.round_indicators_zoom[data-bs-target] {
    background-color: #E0E0E0;
    text-indent: 0px !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
}
