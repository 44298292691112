.loginpage_container {
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF;
}

.left_part {
    width: 50%;
    background-color: #F2F5FA;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set_left_part {
    width: 100%;
}

.right_part {
    width: 50%;
}

.heading_left_part {
    gap: 12px;
    margin-bottom: 109px;
}

.head {
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    margin: 0;
}

.inner_head {
    font-size: 32px;
    font-weight: 700;
    line-height: 24px;
    color: #546EF3;
}

/* problem_card */

.problem_card {
    border-radius: 16px;
    width: 384px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 14px 0px #0000001A;
    margin-left: 119px;
}

.fault_list_nav_login>.active {
    border-left: 4px solid #546EF3 !important;
    border-right: 4px solid #546EF3 !important;
    border-top: 1px solid #CDD1D7 !important;
    background-color: #F2F5FA !important;
}

.fault_list_nav_login_item {
    border: none !important;
}

.fault_list_nav_login_link_login {
    padding: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    cursor: pointer;
}

.water_leak_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_login_item.active .water_leak_bg {
    background-color: #FFFFFF;
}

.litter_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_login_item.active .litter_bg {
    background-color: #FFFFFF;
}

.road_repair_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_login_item.active .road_repair_bg {
    background-color: #FFFFFF;
}

.street_light_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_login_item.active .street_light_bg {
    background-color: #FFFFFF;
}

.accident_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fault_list_nav_login_item.active .accident_bg {
    background-color: #FFFFFF;
}

.btn_three_dot_set {
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    align-self: flex-start;
    width: 24px;
    height: 24px;
}

.btn_three_dot_set:focus:not(:focus-visible) {
    background-color: transparent;
}

.btn_three_dot_set::after {
    display: none !important;
}

.fault_data_head {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #1D1F22;
    padding-bottom: 2px;
    margin: 0;
}


.date_icon_set {
    margin: 1.33px 2px 0.67px 2px;
}

.date_para {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.fault_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}

.fault_details {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #1D1F22;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.problem_para {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}

.caution_icon_set {
    margin: 1px 2px;
}

/* Map data */

.map_data_set {
    margin-top: 19px;
    margin-bottom: 102px;
    padding-left: 115px;
    height: 312px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-image: url('/public/assets/images/map-image.png');
    background-repeat: no-repeat;
    background-size: 312px 312px;
    background-position: center;
    position: relative;
}

.pipe_img {
    width: 100%;
}

.round_fill_one {
    position: absolute;
    left: 115px;
    bottom: 0px;
}

.round_fill_two {
    position: absolute;
    right: 60px;
    top: -100px;
}

.down_arrow {
    z-index: 1;
    margin-bottom: 6px;
}

.map_data_card {
    z-index: 1;
    width: 331px;
    padding: 8px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 8px 12px 14px 0px #00000029;
    clip-path: polygon(50% 0%, 100% 0, 100% 96%, 53% 96%, 51% 100%, 48% 96%, 0 96%, 0 0);
    margin-bottom: 6px;
}

.head_fault_prob {
    gap: 12px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.btn_arrow_forword {
    margin: 4.5px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    margin: 0;
}

/* Problem carousel */

.indicators_set {
    gap: 8px;
}

.problem_para_head {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin: 0;
    padding-bottom: 12px;
    text-align: center;
}

.problem_sub_para {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F2299;
    margin: 0;
    text-align: center;
}

.indicators_set {
    bottom: -34px;
    margin: 0 !important;
}

.round_indicat {
    width: 8px !important;
    height: 8px !important;
    background-color: #E0E0E0 !important;
    border-radius: 10px !important;
    margin: 0 !important;
    border: none !important;
}

.round_indicat.active {
    background-color: #546EF3 !important;
}

/* right part */

.nhma_img {
    width: 75px;
    height: 80px;
}

/* login card */

.login_card {
    width: 340px;
    margin: auto;
}

.welcome_back_msg {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin: 0;
    padding-top: 42px;
    padding-bottom: 4px;
}

.solution_para {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #1D1F2299;
    margin: 0;
    padding-bottom: 38px;
}

.input_label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83;
    margin: 0;
    padding-bottom: 6px;
}

.email_set {
    padding: 0;
    margin-bottom: 18px;
}

.password_set {
    padding: 0;
    margin-bottom: 16px;
}


.input_set:focus {
    outline: none;
    box-shadow: none;
}

.input_set {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #5D6D83 !important;
    background-color: #F2F5FA !important;
    padding: 13px 16px;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px;
    box-shadow: none !important;
}

.input_set.is-invalid, 
.input_set_pass.is-invalid{
    border: 1px solid #F35959 !important;
    background-image: none !important;
}

.invalid-feedback{
    color: #F35959 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.input_set_pass:focus {
    outline: none;
    box-shadow: none;
}

.input_set_pass {
    font-family: "fontello";
    font-style: normal;
    font-weight: bold;
    color: #5D6D83 !important;
    padding: 8px 16px;
    background-color: #F2F5FA !important;
    border: 1px solid #E5E8ED !important;
    border-radius: 8px;
    font-size: 25px;
    line-height: 30px;
    text-align: left;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 5px;
    box-shadow: none !important;
}

.input_set_pass::placeholder{
    font-size: 16px;
    line-height: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    letter-spacing: normal;
}

.btn_login:disabled {
    background-color: #D2D8DF !important;
}

.btn_login {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #FFFFFF !important;
    background-color: #546EF3 !important;
    padding: 14px;
    border: none !important;
    border-radius: 8px;
}

.link_forget_password {
    margin-bottom: 0px;
    margin-top: 30px;
    float: right;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #546EF3;
    cursor: pointer;
    text-decoration: none;
}

.footer_img {
    position: fixed;
    bottom: 0px;
    width: inherit;
}

.img_footer {
    width: 100%;
}

.sel_city_drop_menu {
    width: 341px;
    padding: 8px 8px 0px 8px !important;
    /* border: none !important; */
    border-radius: 8px !important;
    /* transform: translate(0px, 53px) !important; */
}

.set_city_link {
    cursor: pointer !important;
    padding: 16px 8px !important;
    border-radius: 8px !important;
    background-color: #FFFFFF !important;
    margin: 0;
    margin-bottom: 8px;
}


.set_city_link:hover {
    background-color: #F2F5FA !important;
}

.set_city_link.active {
    border: 1px solid #546EF3 !important;
    background-color: #FFFFFF !important;
}

.set_default {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #546EF3;
}


.btn_sel_city {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #5D6D83;
    width: 341px;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
    outline: none;
    border: 1px solid #E5E8ED !important;
    background: #F2F5FA !important;
}

.btn_sel_city:focus:not(:focus-visible) {
    background-color: #F2F5FA;
}

.btn_sel_city:hover {
    background-color: #F2F5FA;
    outline: none;
}

.btn_sel_city::after {
    position: absolute;
    top: 22px;
    right: 23px;
    width: 10px;
    height: 5px;
}

.city_link_set {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.city_name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #1D1F22;
}
