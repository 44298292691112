
.btn_three_dot_set {
    padding: 0 !important;
    border: none !important;
}

.btn_three_dot_set:focus:not(:focus-visible) {
    background-color: transparent;
}

.btn_three_dot_set::after {
    display: none !important;
}

.three_dot_icon_set {
    margin: 3px 10px;
    align-self: flex-start;
}

.btn_three_dot_dropdown_menu {
    padding: 0px !important;
    border: 1px solid #CDD1D7 !important;
    border-radius: 8px !important;
    min-width: 109px !important;
    transform: translate(0px, 33px) !important;
}

.btn_three_dot_dropdown_menu_item:active {
    background-color: #cacaca !important;
}

.btn_three_dot_dropdown_menu_item {
    width: 109px !important;
    padding: 12px 16px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 22px;
    color: #1D1F22 !important;
    border-radius: 8px !important;
}

.delete_item_drop_down {
    align-self: flex-start;
}

.similer_problem_list_item {
    padding: 16px;
    border-bottom: 1px solid #CDD1D7;
}

.similer_problem_list_item:last-child {
    border-radius: 0px 0px 8px 8px;
    border-bottom: 0px;
}

.similer_problem_list_item:first-child {
    border-radius: 8px 8px 0px 0px;
}

.problem_name {
    gap: 12px;
    padding-bottom: 12px;
}
