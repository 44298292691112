@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
    overflow-x: hidden;
    height: 100vh;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

#root .toast_successfully_login {
    position: relative;
    top: 70px;
    max-width: 540px;
    background-color: #1971C2;
    padding: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #ffffff;
    gap: 8px;
    animation-duration: 0.5s !important;
}

#root .toast_successfully_login div:first-child {
    background-color: #ffffff;
    border-radius: 50%;
}

#root .toast_successfully_login div:first-child::after {
    border-color: #1971C2;
}

#root .toast_successfully_login div:last-child {
    margin: 0px;
}

.gm-control-active.gm-fullscreen-control{
    display: none;
}

img[alt="Google"]{
    display: none;
}

.gmnoprint{
    display: none;
}

.gm-style-cc{
    display: none;
}
