.map_data_card_google_map {
    z-index: 2;
    width: 331px;
    padding: 8px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 8px 12px 14px 0px #00000029;
    clip-path: polygon(50% 0%, 100% 0, 100% 96%, 53% 96%, 51% 100%, 48% 96%, 0 96%, 0 0);
    margin-bottom: 6px;
    position: fixed;
}

.map_data_card_google_map .head_fault_prob {
    gap: 12px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.map_data_card_google_map .btn_arrow_forword {
    margin: 4.5px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    margin: 0;
}

.map_data_card_google_map .set_fault_head {
    gap: 12px;
}

.map_data_card_google_map .pipe_img {
    width: 100%;
}

.map_data_card_google_map .litter_bg {
    width: 40px;
    height: 40px;
    background-color: #E8F1F9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map_data_card_google_map .fault_data_head {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #1D1F22;
    padding-bottom: 2px;
    margin: 0;
}

.map_data_card_google_map .date_para {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.map_data_card_google_map .date_icon_set {
    margin: 1.33px 2px 0.67px 2px;
}

.map_data_card_google_map .fault_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #556A87;
}
